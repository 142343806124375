


































import Vue from 'vue';

interface Validation {
  [index: string]: string|null,
  email: string|null,
}

export default Vue.extend({
  data() {
    return {
      error: null,
      form: {
        email: '',
      },
      isSubmitting: false,
      validation: {
        email: null,
      } as Validation,
    };
  },
  methods: {
    async submit(): Promise<void> {
      if (this.validate()) return;

      this.isSubmitting = true;

      await this.$store.dispatch('account/email/CreateEmail', {
        form: this.form,
      }).catch((e) => { this.error = e; });

      this.isSubmitting = false;

      if (!this.error) this.form.email = '';
    },
    validate(validation: Validation = {
      email: null,
    }): boolean {
      const { form: F } = this;
      const v: Validation = { ...validation };

      if (!F.email) {
        v.email = 'notOptional';
      } else if (!F.email.includes('@')) {
        v.email = 'email';
      } else if (F.email.length > 255) {
        v.email = 'length';
      }

      this.validation = v;
      // Check if all keys of v are null, will return false(=!true)
      return !Object.keys(v).every((k) => v[k] === null);
    },
  },
});
