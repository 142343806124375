
















































import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    email: {
      required: true,
      type: Object as () => ApiResource.Email,
      validator(obj: any): obj is ApiResource.Email {
        return obj.object === 'email_address';
      },
    },
    isActive: Boolean,
  },
  methods: {
    makePrimary() {
      this.$emit('email-change', this.email);
    },
    deleteEmail() {
      this.$emit('email-delete', this.email);
    },
  },
});
