
















import Vue from 'vue';
import EmailForm from '@/components/account/EmailForm.vue';
import EmailList from '@/components/account/EmailList.vue';

export default Vue.extend({
  components: {
    EmailForm,
    EmailList,
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
