









































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import AuthenticationAttempt from '@/components/auth/Attempt.vue';
import MediaEmail from '@/components/media/Email.vue';

interface VuexBindings {
  account: ApiResource.Account,
  list: ApiResource.Email[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    AuthenticationAttempt,
    MediaEmail,
  },
  data() {
    return {
      email: null as null|ApiResource.Email,
      error: null,
      isLoading: true,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Get',
      list: 'account/email/List',
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.isLoading = true;

      await this.$store.dispatch('account/email/ListEmails')
        .catch((e) => { this.error = e; });

      this.isLoading = false;
    },
    deleteEmail(email: ApiResource.Email) {
      this.$store.dispatch('account/email/DeleteEmail', { EMAIL_ID: email.id })
        .catch((e) => { this.error = e; });
    },
    async changeEmail(email: ApiResource.Email, TOKEN: string) {
      this.email = email;

      if (!TOKEN) {
        this.showAuthenticationModal(true);
        return;
      }

      await this.$store.dispatch('account/email/ChangeEmail', {
        EMAIL_ID: email.id,
        TOKEN,
      }).catch((e) => { this.error = e; });

      this.showAuthenticationModal(false);
    },
    showAuthenticationModal(bool: boolean) {
      this.showModal = bool;
    },
    handleAttempt(attempt: any) {
      if (!this.email) return;
      this.changeEmail(this.email, attempt.id.concat(attempt.secret));
    },
  },
});
